.bs-table {
  table-layout: fixed;
  width: 100%;
  font-size: 14px;
  color: #292e30;

  th,
  td {
    padding: 12px 0;
    border-bottom: 1px solid #d0d0d0dd;

    &.overflow {
      overflow: visible;
    }
  }

  th {
    font-weight: 600;
  }

  td {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .mat-checkbox-layout {
    margin: 0;
  }

  &.wrap-text {
    td {
      white-space: pre-wrap;
    }
  }

  &.auto-layout {
    table-layout: auto;
  }

  &.cursor-pointer-rows {
    td {
      cursor: pointer;
    }
  }

  .menu-container {
    display: flex;
    align-items: center;
    flex-grow: 0;
  }
}
