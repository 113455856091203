.validation-tooltip {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: Poppins;
  font-size: 12px;
  line-height: 24px;
  position: absolute;
  border-radius: 5px;
  padding: 12px;
  border: 1px solid #e1e1e1;
  background-color: white;

  div {
    padding-left: 24px;
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: left;

    &.valid-item {
      background-image: url('/assets/images/validation-tooltip/checkmark.svg');
    }

    &.error-item {
      color: #e23600;
      background-image: url('/assets/images/validation-tooltip/crossmark.svg');
    }
  }

  &::before {
    content: '';
    position: absolute;
    border-style: solid;
  }

  &::after {
    content: '';
    position: absolute;
    border-style: solid;
    margin-top: -10px;
  }

  &.top:before {
    border-color: #e1e1e1 transparent;
    border-width: 10px 10px 0;
    left: 45%;
    bottom: -10px;
  }

  &.top:after {
    border-color: #ffffff transparent;
    border-width: 10px 10px 0;
    left: 45%;
    bottom: -8px;
  }

  &.right:before {
    border-color: transparent #e1e1e1;
    border-width: 11px 11px 11px 0;
    top: 50%;
    left: -11.5px;
    margin-top: -11px;
  }

  &.right:after {
    border-color: transparent #ffffff;
    border-width: 10px 10px 10px 0;
    top: 50%;
    left: -10px;
  }
}
