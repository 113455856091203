.bs-counter {
  display: flex;
  flex-direction: column;
  color: #292e30;
  font-weight: 600;

  .counter-value {
    font-size: 36px;
    line-height: 42px;

    &.red {
      color: #e25874;
    }

    &.green {
      color: #51a351;
    }
  }

  .counter-name {
    font-size: 13px;
  }

  &.space-right {
    padding-right: 70px;
  }

  &.space-left {
    padding-left: 70px;
  }

  &.border-right {
    border-right: 1px solid #707070;
  }
}
