@import '../vars';

.widget-content-wrapper {
  color: $c-dim-gray;
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 13px;

  .widget-header {
    font-size: 17px;
    color: $c-cod-grey;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .widget-edit-button {
    color: $c-main;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    background-color: initial;
  }
}
