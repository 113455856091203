.select-file-item {
  max-width: 145px;
  height: 80px;
  border: 1px solid #707070;
  border-radius: 5px;
  background-color: #363a3c;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  img {
    width: 37px;
  }

  mat-icon {
    height: 37px;
  }

  &.upload-image {
    border-style: dashed;
  }
}
