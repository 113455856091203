@import '../../styles/vars';

.bs-radio {
  .mat-radio-button .mat-radio-inner-circle,
  .mat-radio-button .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button:active .mat-radio-persistent-ripple {
    background-color: $c-main;
  }

  .mat-radio-button.mat-radio-checked .mat-radio-outer-circle {
    border-color: $c-main;
  }

  .mat-radio-outer-circle {
    background-color: $c-montana-gray;
    border: 1px solid $c-dim-gray;
    width: 18px;
    height: 18px;
  }

  .mat-radio-inner-circle {
    width: 18px;
    height: 18px;
  }

  .mat-radio-button {
    .mat-radio-ripple {
      display: none;
    }

    &.mat-accent {
      &.mat-radio-checked {
        .mat-radio-inner-circle {
          transform: scale(0.7);
        }
      }
    }
  }

  &.minimal {
    .mat-radio-container {
      display: none;
    }

    .mat-radio-checked {
      .mat-radio-label-content {
        color: $c-main;
      }
    }
  }
}
