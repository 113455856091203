// USAGE: <progressbar class="custom-progress-bar" [value]="yourValue"></progressbar>

.custom-progress-bar {
  height: 25px;
  border-radius: 50px;
  background-color: #ffffff;
  border: 1px solid #757575;
  min-width: 100px;

  .progress-bar {
    background-color: var(--color-primary);
  }
}
