@import '../vars';

.bs-slide-toggle.bs-slide-toggle {
  &.mat-slide-toggle {
    .mat-slide-toggle-content {
      font-family: $f-main;
      font-size: 14px;
    }
  }

  &.purple.mat-slide-toggle.mat-checked {
    &:not(.mat-disabled) {
      .mat-slide-toggle-bar {
        background-color: $c-main;
      }
    }

    .mat-ripple-element {
      background-color: $c-main;
    }
  }

  &.cyan.mat-slide-toggle.mat-checked {
    .mat-slide-toggle-bar {
      background-color: $c-cyan;
    }

    .mat-ripple-element {
      background-color: $c-cyan;
    }
  }

  &.magenta.mat-slide-toggle.mat-checked {
    .mat-slide-toggle-bar {
      background-color: #bd2e7e;
    }

    .mat-ripple-element {
      background-color: #bd2e7e;
    }
  }

  &.thumb-white.mat-slide-toggle.mat-checked:not(.mat-disabled) {
    .mat-slide-toggle-bar {
      .mat-slide-toggle-thumb {
        background-color: white;
      }
    }
  }

  &.big.mat-slide-toggle {
    .mat-slide-toggle-thumb-container {
      width: 22px;
      height: 22px;
      top: 2px;
    }

    .mat-slide-toggle-bar {
      height: 24px;
      border-radius: 12px;
      width: 46px;

      .mat-slide-toggle-thumb {
        background-color: white;
        width: 20px;
        height: 20px;
        box-shadow: 0 0 1px 1px #909090;
      }
    }

    &.mat-checked {
      .mat-slide-toggle-thumb-container {
        transform: translate3d(24px, 0, 0);
      }
    }
  }

  .mat-ripple {
    display: none;
  }
}
