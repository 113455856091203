.image-library-selector {
  display: grid;
  grid-template-columns: repeat(auto-fill, 140px);
  grid-row-gap: 17px;
  grid-column-gap: 13px;
  padding-bottom: 20px;
  margin-right: 10px;

  .image-library-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 140px;
    padding-left: 0;
    padding-right: 0;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
    font-size: 17px;
    font-weight: 600;
    color: #363636;
    background-color: unset;

    img {
      max-width: 60px;
      max-height: 60px;
    }
  }

  .image-library-item-text {
    margin-top: 10px;
  }
}

.image-library {
  .image-item {
    height: 115px;
    transition: transform 200ms ease-out;
    cursor: pointer;
    position: relative;

    &:hover {
      transform: scale(1.1);
      z-index: 1;
    }
  }
}
