@import 'vars';

#lpb-button {
  color: #757575;
  cursor: pointer;
  margin: 0 10px;
  outline: none;
  border-radius: 5px;
  box-shadow: none;
  min-height: 33px;
}

.b-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 32px;
  min-width: 130px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  transition: opacity 250ms;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border: 0;

  &.m-white {
    background-color: #fff;
    color: #000 !important;
    border: 1px solid #e0e0e0 !important;
  }

  &.m-ghost {
    background-color: transparent;
    border-width: 1px !important;
    border-style: solid !important;
    box-shadow: none;
  }

  &.m-simple {
    background-color: transparent;
    color: #292e30 !important;
    box-shadow: none;
  }

  &.m-white-and-grey {
    background: #ffffff;
    color: #292e30 !important;
  }

  &:hover {
    color: #fff;
    text-decoration: none;
    opacity: 0.7;

    &.m-white {
      color: #000;
    }
  }
  &[disabled] {
    background-color: #ececec !important;
    opacity: 1 !important;
    &:hover {
      cursor: not-allowed;
      opacity: 1 !important;
      background-color: #ececec !important;
      color: #000 !important;
    }
  }

  &.lpb-button {
    &-default {
      @extend #lpb-button;
      background: none;
      border: 1px solid #757575;

      &:hover {
        color: unset;
      }
    }

    &-ghost {
      @extend #lpb-button;
      background: none;
      border: 1px solid #fff;
      color: #fff;
      margin-left: 0;
      margin-right: 0;

      &:hover {
        color: #fff;
      }
    }

    &-main {
      @extend #lpb-button;
      background-color: $c-main;
      color: #ffffff;
    }

    &-empty-background {
      @extend #lpb-button;
      border: 2px solid $c-main;
      color: $c-main;
      background: #ffffff;
    }

    &-empty-background-danger {
      @extend #lpb-button;
      border: 2px solid #dc3545;
      color: #dc3545;
      background: #ffffff;
    }
    &-empty-background-warning {
      @extend #lpb-button;
      border: 2px solid #ffc107;
      color: #ffc107;
      background: #ffffff;
    }

    &-custom-color {
      @extend #lpb-button;
      background-color: var(--lpb-main-color);
      color: #ffffff;
      transition: background-color 200ms;
    }

    &-danger {
      @extend #lpb-button;
      background-color: #dc3545;
      color: #ffffff;
    }

    &-warning {
      @extend #lpb-button;
      background-color: #ffc107;
      color: #ffffff;
    }
  }
}

.b-button-default {
  background-color: #475b63;
}

.b-button-admin {
  background-color: #5faee3;
  color: #fff;
  font-size: 12px;
  padding: 8px 25px;
  cursor: pointer;

  &:hover,
  &:visited {
    color: #fff;
  }

  &.warning {
    background-color: #ff0000;
  }

  &.success {
    background-color: #2ecc71;
  }

  &.ghost {
    border: 1px solid #707070;
    background-color: transparent;
    color: #9197a0;

    &:hover,
    &:visited {
      color: #9197a0;
    }
  }
}

.b-button-back {
  cursor: pointer;
  display: inline-block;

  .b-button-back-link {
    color: #616161;
    text-decoration: none;
  }
}

.test-button {
  margin-left: 15px;
}

.bs-button-tooltip {
  line-height: 24px;
  font-weight: 400;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  white-space: pre-wrap;
  text-align: center;
  max-width: 360px !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
}
