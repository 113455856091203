@import '../vars';

.bs-form-field {
  display: block;
  width: 100%;
  min-width: 0;
  border-radius: 5px;
  overflow: hidden;

  &.mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  &.mat-form-field-appearance-outline .mat-form-field-flex {
    margin-top: 0;
  }

  .mat-form-field-flex {
    background-color: $c-montana-gray;
  }

  &.mat-form-field-appearance-outline .mat-form-field-outline {
    color: $c-dim-gray;
    top: 0;
  }

  .mat-form-field-infix {
    padding: 2px;
    border-top: 0;
    font-size: 14px;
    width: 100%;
    line-height: 28px;
  }

  &.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    position: static;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  &.mat-form-field-appearance-outline {
    .mat-form-field-prefix,
    .mat-form-field-suffix {
      font-size: 12px;
      top: 0;
    }

    .mat-form-field-suffix {
      color: #a9a9a9;
      padding-left: 5px;
    }

    .mat-form-field-prefix {
      color: #a9a9a9;
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  .mat-error {
    margin-top: 7px;
  }

  .mat-chip-input {
    width: auto;
    margin: auto;
    flex: auto;
  }

  // Input styles
  .mat-input-element {
    font-size: 12px;
    border-radius: 5px;

    &::placeholder {
      color: #888888;
    }

    &:disabled {
      color: rgba(255, 255, 255, 0.38);
    }

    // fix input autofill styles
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px $c-montana-gray inset;
      -webkit-text-fill-color: #fff;
    }

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    option {
      background-color: $c-montana-gray;
    }
  }

  .mat-checkbox,
  label {
    font-size: 12px;
  }

  // Textarea styles
  textarea.mat-input-element {
    resize: none;
    line-height: 1.6;
    color: #fff;

    &::-webkit-scrollbar-track {
      background-color: $c-montana-gray;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $c-dim-gray;
    }
  }

  &.textarea-resizable {
    textarea.mat-input-element {
      resize: vertical;
    }
  }

  .mat-form-field-infix {
    height: auto;
  }

  //Select styles
  &.mat-form-field-type-mat-native-select.mat-form-field-appearance-outline,
  .mat-select {
    .mat-form-field-infix::after {
      margin-top: -2px;
      color: #fff;
      pointer-events: none;
    }

    .mat-form-field-flex {
      position: relative;

      &:after {
        content: '';
        display: block;
        height: 100%;
        width: 1px;
        background-color: $c-dim-gray;
        position: absolute;
        right: 30px;
        top: 0;
      }
    }
  }

  .mat-select {
    .mat-select-value {
      color: white;
    }
  }

  //Сheckbox styles
  &.mat-checkbox {
    display: inline-block;
    width: auto;
    max-width: 100%;

    &.mat-checkbox-checked {
      .mat-checkbox-inner-container .mat-checkbox-background {
        background-color: $c-main !important;
      }
    }

    &.mat-checkbox-indeterminate {
      .mat-checkbox-inner-container .mat-checkbox-background {
        background-color: $c-main !important;
      }
    }

    &.mat-checkbox-disabled {
      opacity: 0.4;

      .mat-checkbox-label {
        color: inherit !important;
      }
    }

    .mat-checkbox-layout {
      max-width: 100%;
    }

    .mat-checkbox-label {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  //Selection list styles
  &.mat-selection-list {
    .mat-list-item {
      height: 36px !important;

      * {
        color: white;
        font-size: 14px;
      }

      .mat-pseudo-checkbox {
        transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1), background-color 90ms cubic-bezier(0, 0, 0.2, 0.1),
          opacity 90ms cubic-bezier(0, 0, 0.2, 0.1);

        &.mat-pseudo-checkbox-checked {
          background-color: $c-main !important;
        }

        &:not(.mat-pseudo-checkbox-checked) {
          opacity: 0.4;
        }
      }

      .mat-list-item-content {
        padding-left: 0 !important;
      }
    }
  }

  //Tree styles
  &.mat-tree {
    background: none;

    * {
      color: white !important;
      font-size: 14px !important;
    }

    .mat-tree-node {
      height: 36px;
      min-height: 36px;

      .mat-icon {
        font-size: 24px !important;
      }

      .mat-checkbox-layout {
        margin-bottom: 0;
      }
    }
  }

  .mat-datepicker-toggle button {
    position: absolute;
    top: -20px;
    right: 0;
    background-color: transparent;

    .mat-button-ripple {
      display: none;
    }

    .mat-button-focus-overlay {
      display: none;
    }
  }

  .mat-select-value {
    font-size: 12px;
  }

  .mat-select-arrow {
    color: #ffffff;
  }

  &.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateX(7px);
  }

  .mat-select-value::after {
    content: '';
    width: 1px;
    display: block;
    height: 30px;
    position: absolute;
    top: -8px;
    background-color: #707070;
    right: 15px;
  }

  &.search {
    .mat-form-field-flex::after {
      content: '';
      display: block;
      height: 100%;
      width: 1px;
      background-color: #707070;
      position: absolute;
      right: 31px;
      top: 0;
    }

    .mat-form-field-infix::after {
      content: '\e8b6';
      font-family: 'Material Icons';
      position: absolute;
      top: 2px;
      right: -8px;
      font-size: 21px;
    }
  }

  &.light {
    .mat-form-field-flex {
      background-color: white;
    }

    &.mat-form-field-type-mat-native-select.mat-form-field-appearance-outline {
      .mat-form-field-infix::after {
        color: $c-montana-gray;
      }
    }

    &.mat-form-field-type-mat-native-select.mat-form-field-appearance-outline,
    .mat-select {
      .mat-form-field-flex {
        &:after {
          background-color: #cecece;
        }
      }
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-start {
      border-radius: 1px 0 0 1px;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-end {
      border-radius: 0 1px 1px 0;
    }

    .mat-form-field-outline-start {
      border-color: #cecece;
      border-radius: 2px 0 0 2px;
    }

    .mat-form-field-outline-end {
      border-color: #cecece;
      border-radius: 0 2px 2px 0;
    }

    .mat-input-element {
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px white inset;
        -webkit-text-fill-color: $c-montana-gray;
      }

      option {
        background-color: transparent;
      }
    }

    .mat-datepicker-toggle button {
      color: $c-montana-gray;
    }

    textarea.mat-input-element {
      color: $c-montana-gray;
    }

    .mat-select-arrow {
      color: #363a3c;
    }

    .mat-select {
      .mat-select-value {
        color: inherit;
      }
    }
  }

  &.select-chips {
    .mat-form-field-infix {
      height: auto;
    }

    .mat-select-trigger {
      height: 100%;
    }

    .mat-select-value {
      line-height: 24px;
      max-height: 84px;
      overflow: auto;

      .mat-select-placeholder {
        color: white;
      }

      &::after {
        height: calc(100% + 6px);
        min-height: 31px;
        top: -4px;
      }
    }

    .mat-chip {
      font-size: 12px;
      padding: 5px 3px 3px 9px;
      min-height: 22px;
      background-color: rgba(255, 255, 255, 0.16);
      color: white;
    }

    .mat-chip-remove {
      margin-bottom: 2px;
      opacity: 0.7;
      color: white;
    }

    .mat-chip-list-wrapper {
      margin-bottom: -1px;
    }
  }
}
