.bs-control-box {
  margin-bottom: 15px;
  font-family: $f-main;
  width: 100%;

  .bs-control-box-label {
    display: block;
    margin-bottom: 7px;
    font-size: 12px;
    line-height: 24px;
  }

  .bs-control-box-tip {
    font-size: 12px;
    opacity: 0.5;
  }

  &.bs-image-upload-box {
    .image-upload-preview {
      margin-bottom: 0;
      margin-right: 0;

      img {
        width: 100%;
        height: auto !important;
      }
    }
  }
}
