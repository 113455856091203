.admin-page-heading-title {
  font-size: 24px;
}

.admin-page-heading-highlighted {
  display: inline-block;
  padding-bottom: 10px;
  border-bottom: 3px solid #fc7d74;
}

.admin-page-heading-info {
  font-size: 16px;
  color: red;
}
