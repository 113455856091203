.no-search-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  .mat-icon {
    width: 100px;
    height: 100px;
  }

  span {
    color: #757575;
    margin-top: 20px;
  }
}
