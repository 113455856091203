@import '../vars';

@each $color, $value in $colors {
  .spinner-color-#{'' + $color} {
    &.mat-progress-spinner circle,
    &.mat-spinner circle {
      stroke: $value;
    }
  }
}
