//TODO delete nm-material-theme once better solution is developed
.nm-material-theme .mat-form-field {
  display: block;
  width: 100%;
  min-width: 0;
  margin: 4px 0;
  color: #0000001f;

  &.mat-form-field-has-label {
    padding-top: 32px;
  }

  // This style was removed in Angular Material 15, so until we remove all legacy mat-form-fields - we added it
  // here to make it look like it did before the migration
  .mat-form-field-wrapper {
    padding-bottom: 1.34375em;
  }

  &.nm-no-hint .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  &.mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
  }

  &.mat-form-field-appearance-outline .mat-form-field-flex {
    margin-top: 0;
    padding: 0 8px;
    border-radius: 5px;
    background: #ffffff;
  }

  &.mat-form-field-appearance-outline .mat-form-field-outline {
    outline-width: 1px;
    top: 0;
  }

  &.mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline-thick {
    outline-width: 1px;
  }

  &.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    outline-width: 1px;
  }

  &.mat-form-field-appearance-outline.mat-form-field-disabled {
    pointer-events: none;

    .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
      opacity: 0.38;
    }
  }

  &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
    border-top-color: initial !important;
  }

  // This style was removed in Angular Material 15, so until we remove all legacy mat-form-fields - we added it
  // here to make it look like it did before the migration
  &.mat-form-field-appearance-outline .mat-form-field-label-wrapper .mat-form-field-label {
    top: 1.84375em;
    margin-top: -0.25em;
  }

  &.mat-form-field-appearance-outline .mat-form-field-prefix {
    top: 1px;
    opacity: 0.3;
    font-size: 14px;

    + .mat-form-field-infix .mat-form-field-label-wrapper {
      margin-left: -59px;
    }
  }

  .mat-form-field-infix {
    padding: 8px;
    border-top: 0;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    color: #292e30;

    .mat-select {
      .mat-select-value {
        box-sizing: border-box;
        padding-right: 32px;
      }
    }
  }

  &.mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 1px;
    opacity: 0.3;
    font-size: 14px;
  }

  .mat-form-field-label-wrapper {
    margin-left: -8px;
    top: -32px;
    padding-top: 32px;
    font-weight: 600;
    color: #292e30;

    .mat-form-field-label {
      color: inherit;
    }
  }

  &.mat-focused {
    .mat-form-field-label-wrapper .mat-form-field-label {
      color: #bd2e7e;
    }
  }

  &.mat-form-field-invalid {
    .mat-form-field-label-wrapper .mat-form-field-label {
      color: #e25874;
    }
  }

  &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-outline.mat-form-field-can-float,
  .mat-input-server:focus,
  + .mat-form-field-label-wrapper,
  .mat-form-field-label {
    transform: translateY(-24px) scale(1);
  }

  &.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper,
  .mat-form-field-hint-wrapper {
    margin-top: 0;
    padding: 0;
  }

  .mat-error,
  .mat-hint {
    font-size: 12px;
    line-height: 24px;
  }

  .mat-input-element {
    font-size: 14px;

    &::placeholder {
      color: #535353;
      opacity: 0.5;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 40px #fff inset;
    }
  }

  &.mat-form-field-appearance-outline.mat-form-field-type-mat-select {
    .mat-form-field-flex {
      position: relative;

      .mat-select-arrow-wrapper {
        transform: translateY(-5px);

        .mat-select-arrow {
          margin-top: 8px;
          margin-right: 0;
          margin-left: -11px;
          border: solid;
          border-width: 0 1px 1px 0;
          height: 10px;
          width: 10px;
          box-sizing: border-box;
          transform-origin: 6px 6px;
          transform: rotate(45deg);
          transition: 600ms cubic-bezier(0.25, 0.8, 0.25, 1) 0ms;
          transition-property: transform, border-color;
        }
      }

      &::after {
        content: '';
        display: block;
        height: 100%;
        width: 1px;
        background-color: #000000;
        opacity: 0.12;
        position: absolute;
        right: 40px;
        top: 0;
      }

      &:hover {
        &::after {
          width: 2px;
          height: 100%;
          opacity: 0.87;
          background-color: #000000;
          transition: opacity 600ms cubic-bezier(0.25, 0.8, 0.25, 1) 0ms;
        }

        .mat-select-arrow-wrapper .mat-select-arrow {
          border-color: #000000;
          border-width: 0 2px 2px 0;
        }
      }
    }

    &.mat-focused .mat-form-field-flex {
      &::after {
        width: 2px;
        height: 100%;
        background-color: #bd2e7e;
        opacity: 1;
        transition: none;
      }

      .mat-select-arrow-wrapper .mat-select-arrow {
        border-color: #bd2e7e;
        border-width: 0 2px 2px 0;
        transform: rotate(225deg);
        transition-property: transform;
      }
    }

    &.mat-form-field-invalid .mat-form-field-flex {
      &::after {
        width: 2px;
        height: 100%;
        background-color: #e25874;
        opacity: 1;
        transition: none;
      }

      .mat-select-arrow-wrapper .mat-select-arrow {
        border-color: #e25874;
        border-width: 0 2px 2px 0;
      }
    }
  }

  .mat-select-trigger {
    min-height: inherit;

    .mat-chip-list-wrapper {
      overflow: auto;
    }

    .mat-chip.mat-standard-chip {
      font-size: 12px;
      cursor: pointer;
      background-color: #ffffff;
      color: #292e30;
      padding: 0 16px;
      min-height: 30px;

      &::after {
        background-color: #ffffff;
      }

      &.mat-primary {
        background-color: #bd2e7e;
        color: #ffffff;
      }

      &.mat-accent {
        background-color: #bd2e7e;
        color: #ffffff;
      }

      .mat-chip-remove {
        color: #ffffff;
        opacity: 1;
      }
    }
  }
}

.nm-select-dropdown-panel {
  margin-top: 40px;
}

.mat-form-field-autofill-control {
  color: #292e30;
}
