.bs-sections-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
}

.bs-sections-block-title {
  font-size: 14px;
  color: #ffffff;
  width: 100%;
  padding: 16px;
}

.bs-section-box {
  height: 75px;
  display: inline-block;
  max-width: 160px;

  .bs-section-item {
    height: 100%;
    border-radius: 5px;
    background-color: #363a3c;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &.disabled {
      background-color: #2e3335;
      cursor: default;
    }

    upln-tooltip {
      position: absolute;
      right: 7px;
      top: 0;
    }

    .mat-icon {
      margin: 8px 0;
    }
  }

  .bs-section-types-item-title {
    font-size: 11px;
    color: #ffffff;
  }

  .bs-section-types-disabled-item-title {
    font-size: 11px;
    color: #7c8081;
  }
}
