.drop-section-placeholder {
  padding-top: 5px;
  padding-bottom: 5px;
  border: 2px dashed #027ffd;
  text-align: center;
  color: #027ffd;
  z-index: 2;
}

.sections-drop-zone {
  position: relative;

  &:not(.no-overlay).cdk-drop-list-receiving,
  &.footer-receiving {
    &:after {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      border: 4px dashed rgb(99, 177, 255);
      pointer-events: none;
      content: attr(data-drop-section-text);
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 26px;
    }
  }

  &.cdk-drop-list-receiving,
  &.cdk-drop-list-dragging {
    cursor: move;
  }

  &.cdk-drop-list-dragging .sections-drop-zone-item {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .footer-drop-placeholder {
    opacity: 0;
    height: 0;
    padding: 0;
    border: 0;
  }

  &.footer-receiving {
    .sections-drop-zone-item {
      transform: none !important;
    }
  }
}

.cdk-drop-list-dragging .editable-section:hover .editable-section-overlay:not(.section-edit) {
  opacity: 0;
}
