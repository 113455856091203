.drag-and-drop-list {
  .cdk-drag-placeholder {
    opacity: 0;
  }

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  &.cdk-drop-list-dragging .drag-and-drop-list-item:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

.drag-and-drop-list-item {
  margin-bottom: 10px;

  .drag-and-drop-list-item-action {
    background-color: transparent;
    padding: 5px;
    color: #fff;
    border: 0;

    .mat-icon {
      display: block;
    }
  }

  .drag-and-drop-list-item-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px;
  }
}

.drag-and-drop-list-panel {
  background-color: $c-montana-gray;
  padding: 8px 0;
  border-radius: 5px;
  display: flex;
}
