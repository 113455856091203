/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 40px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
  order: 3;
  margin-bottom: 8px;
}
.toast-message a,
.toast-message label {
  color: #ffffff;
}
.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  float: right;
  font-size: 24px;
  font-weight: lighter;
  color: #292e30;
  text-shadow: 0 1px 0 #ffffff;
  order: 2;
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
.toast-close-button + .toast-message {
  order: 1;
  margin-bottom: 0;
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .ngx-toastr {
  display: grid;
  grid-template-columns: auto 24px;
  align-items: center;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 4px 12px 4px 44px;
  width: 320px;
  background-position: 12px 12px;
  background-repeat: no-repeat;
  background-size: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #292e30;
  font-size: 12px;
}
.toast-info {
  background-image: url('../../assets/images/notifications/info.svg');
}
.toast-error {
  background-image: url('../../assets/images/notifications/error.svg');
}
.toast-success {
  background-image: url('../../assets/images/notifications/success.svg');
}
.toast-warning {
  background-image: url('../../assets/images/notifications/warning.svg');
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.ngx-toastr {
  background-color: #fff;
  pointer-events: auto;
}

.toast-success .toast-title {
  color: #bd2e7e;
}

.toast-success .toast-progress {
  background-color: #bd2e7e;
}

.toast-error .toast-title {
  color: #e23600;
}

.toast-error .toast-progress {
  background-color: #e23600;
}

.toast-warning .toast-title {
  color: #fdc353;
}

.toast-warning .toast-progress {
  background-color: #fdc353;
}

.toast-info .toast-title {
  color: #195bde;
}

.toast-info .toast-progress {
  background-color: #195bde;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 6px;
  height: 2px;
  background-color: #000000;
  opacity: 1;
}

/*Progress bar grid order */
.ngx-toastr div:last-child {
  order: 4;
}
