.nm-material-theme .mat-checkbox {
  &.mat-checkbox-checked {
    &.mat-cyan {
      .mat-checkbox-background,
      .mat-checkbox-frame {
        background-color: #00b9b5;
        border-color: #00b9b5;
      }
    }

    &.mat-primary {
      .mat-checkbox-background,
      .mat-checkbox-frame {
        background-color: #bd2e7e;
        border-color: #bd2e7e;
      }
    }

    &.mat-accent {
      .mat-checkbox-background,
      .mat-checkbox-frame {
        background-color: #bd2e7e;
        border-color: #bd2e7e;
      }
    }

    &.mat-warn {
      .mat-checkbox-background,
      .mat-checkbox-frame {
        background-color: #e25874;
        border-color: #e25874;
      }
    }
  }

  &.mat-checkbox-disabled {
    .mat-checkbox-background,
    .mat-checkbox-frame {
      opacity: 0.54;
    }
  }
}
