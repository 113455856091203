.bs-simple-list {
  .list-item {
    display: flex;
    align-items: center;
    background-color: #363a3c;
    padding: 8px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 7px;
  }

  .edit-button {
    min-width: 90px;
    margin-right: 8px;
  }

  .item-name {
    line-height: 30px;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .delete-icon {
    cursor: pointer;
  }
}
