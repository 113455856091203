.no-uplinks-container {
  margin: 5rem;
}

.organization-title {
  margin-top: 20px;
  font-size: 16px;
}

.organization-name {
  text-transform: uppercase;
  font-weight: 600;
}
