.popover-black {
  .mat-dialog-container {
    overflow: visible;
  }

  .popover-angle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #292e30;
    top: -8px;
    transform: rotate(45deg);
    border-radius: 4px;
  }
}
