$sidebar-small: 340px;
$sidebar-wide: 380px;

.bs-sidebar-offset {
  width: $sidebar-small;

  &.bs-sidebar-offset-wide {
    width: $sidebar-wide;
  }
}

.bs-sidebar-container {
  position: fixed;
  width: $sidebar-small;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: $c-dark;
  color: white;
  overflow-y: auto;

  & > * {
    flex-shrink: 0;
  }

  &.bs-sidebar-container-wide {
    width: $sidebar-wide;
  }

  .bs-sidebar-header {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 58px;
    padding: 0 20px;
    background-color: $c-montana-gray;
    color: #ffffff;

    .bs-sidebar-header-button {
      display: flex;
      width: 50px;
      height: 40px;
      background-color: unset;
      border: 1px solid $c-dim-gray;
      border-radius: 5px;
      padding: 0;
      align-items: center;
      justify-content: center;

      &.cyan {
        &.active {
          border-color: $c-cyan;
        }
      }

      &.purple {
        &.active {
          border-color: $c-main;
        }
      }
    }
    &.light-theme {
      background-color: #ffffff;
    }
  }

  .bs-sidebar-divider {
    width: 100%;
    height: 10px;
    background-color: $c-black;

    &.gray-thin {
      border: 1px solid $c-dim-gray;
      margin: 16px 0;
      width: auto;
      height: 0;
    }
  }

  .bs-sidebar-inline-elements {
    @include inline-grid($sidebar-inline-elements-gap);
  }

  .bs-sidebar-small-input {
    width: 65px;
    margin-left: $sidebar-inline-elements-gap;
  }

  .bs-sidebar-content {
    padding: 20px;
  }

  .bs-sidebar-content-title {
    display: flex;
    align-items: flex-end;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .bs-sidebar-footer {
    margin-top: auto;
    padding: 20px;
  }

  .bs-sidebar-hint {
    font-size: 12px;
    color: #bdbdbd;
    line-height: 1.3;
  }

  .heading-error {
    font-size: 12px;
    color: #f44336;
    margin-bottom: 7px;
  }
  &.light-theme {
    background-color: #ffffff;
  }
}
