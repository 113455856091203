.mat-button-base {
  &.mat-button,
  &.mat-flat-button,
  &.mat-raised-button {
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
  }

  &.mat-stroked-button {
    height: 40px;
    line-height: 38px;
    border-radius: 4px;
    font-weight: 600;
    background: white;
    font-size: 12px;
  }

  &.mat-icon-button {
    height: 40px;
    border-radius: 4px;
  }
}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-flat-button,
.mat-stroked-button {
  &.mat-button-disabled {
    pointer-events: none;
    filter: opacity(0.4);

    &.mat-button-disabled {
      background-color: transparent;
    }
  }
}

.mat-stroked-button {
  &.mat-button-disabled {
    border-color: rgba(0, 0, 0, 0.12);
  }
}

.mat-button,
.mat-icon-button,
.mat-stroked-button {
  &.mat-primary.mat-button-disabled {
    color: #bd2e7e;
  }

  &.mat-accent.mat-button-disabled {
    color: #bd2e7e;
  }

  &.mat-cyan.mat-button-disabled {
    color: #00b9b5;
  }

  &.mat-warn.mat-button-disabled {
    color: #e25874;
  }
}

.mat-button,
.mat-stroked-button {
  &:hover {
    &.mat-primary {
      background-color: rgba(#bd2e7e, 0.1);
    }

    &.mat-accent {
      background-color: rgba(#bd2e7e, 0.1);
    }

    &.mat-cyan {
      background-color: rgba(#00b9b5, 0.1);
    }

    &.mat-warn {
      background-color: rgba(#e25874, 0.1);
    }
  }
}

.mat-icon-button {
  &:hover {
    opacity: 80%;
  }
}

.mat-raised-button,
.mat-flat-button {
  &.mat-primary.mat-button-disabled {
    color: #ffffff;
    background-color: #bd2e7e;
  }

  &.mat-accent.mat-button-disabled {
    color: #ffffff;
    background-color: #bd2e7e;
  }

  &.mat-warn.mat-button-disabled {
    color: #ffffff;
    background-color: #e25874;
  }

  &:hover {
    background-color: rgba(#292e30, 0.04);

    &.mat-primary {
      background-color: rgba(#bd2e7e, 0.8);
    }

    &.mat-accent {
      background-color: rgba(#bd2e7e, 0.8);
    }

    &.mat-cyan {
      background-color: rgba(#00b9b5, 0.8);
    }

    &.mat-warn {
      background-color: rgba(#e25874, 0.8);
    }
  }
}

.nm-underlined-button {
  &.mat-button-base.mat-stroked-button {
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    font-weight: 400;
    text-decoration: underline;
    background-color: transparent;
    border: 0;
    padding: 0;
    min-width: auto;
  }
}
