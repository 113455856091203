.content-section-container {
  .content-sections-list {
    flex-grow: 1;
    overflow-y: auto;
  }
  .content-left-sidebar {
    display: flex;
    flex-direction: column;
  }
  .img-holder-margin {
    margin-bottom: 30px;
  }
  .back-link {
    color: #616161;
    text-decoration: none;
  }
}
