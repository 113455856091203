@import 'vars';

.f {
  @each $size in $sizes {
    &-#{$size} {
      font-size: #{$size}px !important;
    }
  }
}

@each $color, $value in $colors {
  .c-#{'' + $color} {
    color: $value !important;
  }
  .b-#{'' + $color} {
    background-color: $value !important;
  }
  .hover-c-#{'' + $color} {
    &:hover {
      color: $value !important;
    }
  }
}

.flex {
  &-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.text {
  &-center {
    text-align: center;
  }
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }
  &-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-bold {
    font-weight: bold;
  }
  &-underline {
    text-decoration: underline;
  }
  &-uppercase {
    text-transform: uppercase;
  }
  &-capitalize {
    text-transform: capitalize;
  }
  &-lowercase {
    text-transform: lowercase;
  }
}

.m {
  @each $size in $sizes {
    &-r-#{$size} {
      margin-right: #{$size}px !important;
    }
    &-l-#{$size} {
      margin-left: #{$size}px !important;
    }
    &-t-#{$size} {
      margin-top: #{$size}px !important;
    }
    &-b-#{$size} {
      margin-bottom: #{$size}px !important;
    }
    &-#{$size} {
      margin: #{$size}px;
    }
  }
}

.p {
  @each $size in $sizes {
    &-r-#{$size} {
      padding-right: #{$size}px !important;
    }
    &-l-#{$size} {
      padding-left: #{$size}px !important;
    }
    &-t-#{$size} {
      padding-top: #{$size}px !important;
    }
    &-b-#{$size} {
      padding-bottom: #{$size}px !important;
    }
    &-#{$size} {
      padding: #{$size}px;
    }
  }
}

$max: 160;
$offset: 8;
$unit: 'px';
@mixin generic-classes-range-generator($className, $styleNames) {
  $i: 0;
  @while $i <= $max {
    @each $styleName in $styleNames {
      #{$className + $i} {
        #{$styleName}: #{$i + $unit} !important;
      }
    }
    $i: $i + $offset;
  }
}

@include generic-classes-range-generator('.nm-pl-', [ 'padding-left' ]);
@include generic-classes-range-generator('.nm-pr-', [ 'padding-right' ]);
@include generic-classes-range-generator('.nm-pt-', [ 'padding-top' ]);
@include generic-classes-range-generator('.nm-pb-', [ 'padding-bottom' ]);
@include generic-classes-range-generator('.nm-px-', [ 'padding-left', 'padding-right' ]);
@include generic-classes-range-generator('.nm-py-', [ 'padding-bottom', 'padding-top' ]);
@include generic-classes-range-generator('.nm-p-', [ 'padding' ]);

@include generic-classes-range-generator('.nm-ml-', [ 'margin-left' ]);
@include generic-classes-range-generator('.nm-mr-', [ 'margin-right' ]);
@include generic-classes-range-generator('.nm-mt-', [ 'margin-top' ]);
@include generic-classes-range-generator('.nm-mb-', [ 'margin-bottom' ]);
@include generic-classes-range-generator('.nm-mx-', [ 'margin-left', 'margin-right' ]);
@include generic-classes-range-generator('.nm-my-', [ 'margin-bottom', 'margin-top' ]);
@include generic-classes-range-generator('.nm-m-', [ 'margin' ]);
