.upln-ngx-datatable {
  border: 1px solid #eceeef;
  color: rgb(33, 37, 41);

  .datatable-header {
    border-bottom: 2px solid #ccc;
  }

  .datatable-header-cell {
    padding: 12px;
    font-size: 12px;
  }

  .datatable-header-cell + .datatable-header-cell,
  .datatable-body-cell + .datatable-body-cell {
    border-left: 1px solid rgba(#9197a0, 0.25);
  }

  .datatable-header-cell-template-wrap {
    display: flex;
  }

  &.ngx-datatable .datatable-body-cell {
    display: flex;
    align-items: center;
  }

  .datatable-body-cell-label {
    padding: 12px 12px;
    font-size: 12px;
  }

  &.ngx-datatable .datatable-body .datatable-scroll {
    display: block;
  }

  .datatable-row-wrapper:nth-child(even) {
    background-color: #f2f2f2;
  }

  .empty-row {
    padding: 30px;
    text-align: center;
  }

  .danger-row {
    background-color: #f7d6d1;
    border-bottom: 1px solid #dcc5c3;
    border-top: 1px solid #dcc5c3;
  }

  datatable-header-cell {
    &.sortable:before,
    &.sortable:after {
      font-family: 'IcoFont';
      position: absolute;
      top: 6px;
      color: #ccc;
      font-size: 20px;
      content: '\eb53';
      right: 10px;
    }

    &.sortable:after {
      content: '\eb53';
      right: 10px;
    }

    &.sortable:before {
      content: '\eb56';
      right: 15px;
    }

    &.sortable.sort-active.sort-desc:after {
      color: #666;
    }

    &.sortable.sort-active.sort-asc:before {
      color: #666;
    }
  }
}

.ad-details {
  display: flex;
  font-size: 12px;
}

.ad-details-image {
  flex-shrink: 0;
  width: 80px;
  height: 55px;
  margin-right: 33px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.ad-details-headline {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: inherit;
}
