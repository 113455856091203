@import '../vars';

.bs-button.bs-button {
  min-width: 140px;
  margin-bottom: 0;
  padding: 10px;
  border: 1px solid $c-main;
  border-radius: 2px;
  background-color: $c-main;
  color: #fff;
  font-family: $f-main;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  transition: opacity 200ms;
  cursor: pointer;
  font-weight: 600;

  &.thin {
    padding: 5px 10px;
  }

  &.bs-button-responsive {
    min-width: 0;
    width: 100%;
  }

  &.bs-button-black {
    border-color: $c-dim-gray;
    background-color: $c-black;
  }

  &.bs-button-white {
    border-color: white;
    background-color: white;
    color: #363636;
  }

  &.bs-button-gray {
    border-color: $c-dim-gray;
    background-color: $c-montana-gray;
  }

  &.bs-button-brown {
    border-color: $c-brown;
    background-color: $c-brown;
  }

  &.bs-button-teal {
    border-color: $c-teal;
    background-color: $c-teal;
  }

  &.bs-button-magenta {
    border-color: $c-magenta;
    background-color: $c-magenta;
  }

  &.bs-button-ghost {
    border-color: $c-dim-gray;
    background-color: transparent;
    color: $c-dim-gray;
  }

  &.bs-button-ghost-white {
    border-color: $c-dim-gray;
    background-color: transparent;
    color: #fff;
  }

  &.bs-button-simple {
    background-color: transparent;
    border: 0;
  }

  &.bs-button-border-radius-large {
    border-radius: 22px;
  }

  &.bs-button-flex {
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;

    .bs-button-inner-icon {
      position: absolute;
      right: 0;
    }
  }

  &.bs-button-icon {
    min-width: 60px;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: unset;
    color: #fff;
  }

  &:not([disabled]) {
    &:hover {
      opacity: 0.8;
    }

    &:focus {
      outline: none;
    }
  }

  &.no-border {
    border-color: transparent;
  }

  &.text-button {
    font-size: 12px;
    color: $c-main;
    font-weight: 600;
    background-color: transparent;
    padding: 0;
    border: none;
    min-width: 0;

    &.black {
      color: black;
    }
  }
}

.bs-back-link-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 0;

  .mat-icon {
    width: 23px;
    height: 23px;
    margin-right: 5px;
  }
}
