// NOTE: WebKit browsers only (Safari, Chrome, Opera)

.b-custom-scroll-bar {
  &,
  .mat-tab-body.mat-tab-body-active {
    &::-webkit-scrollbar-track {
      background-color: transparent;
      box-shadow: none;
    }
  }

  &,
  .mat-tab-body.mat-tab-body-active {
    &::-webkit-scrollbar {
      width: 10px;
      background-color: transparent;
    }
  }

  &,
  .mat-tab-body.mat-tab-body-active {
    &::-webkit-scrollbar-thumb {
      background-color: #757575;
      border: none;
    }
  }

  &.m-ligth {
    &::-webkit-scrollbar-thumb {
      background-color: #adadad;
    }
  }
}

// need to refactor
.custom-scroll {
  ::-webkit-scrollbar-track {
    background-color: transparent;
    box-shadow: none;
  }

  ::-webkit-scrollbar {
    width: 5px;
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #cacaca;
    border: none;
  }
}
