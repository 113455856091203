.commissions-info-box {
  max-width: 720px;

  .commissions-info-box-title {
    color: #202020;
    font-size: 17px;
    font-weight: 600;
  }

  .commissions-info-box-description {
    color: #696c65;
    font-size: 14px;

    a {
      color: #d10382;
      text-decoration: underline;
    }
  }

  .commissions-info-box-action-button {
    margin-top: 15px;
  }
}
