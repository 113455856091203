.mat-tooltip.bs-tooltip-message {
  color: $c-black;
  background-color: white;
  font-size: 12px;
  line-height: 16px;
  padding: 10px 12px;
  overflow: visible;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  &::before {
    width: 14px;
    height: 14px;
    background-color: white;
    position: absolute;
    content: '';
    transform: rotate(45deg);
    top: calc(50% - 8px);
    border-radius: 2px;
  }

  &.right::before {
    left: -4px;
    box-shadow: -2px 5px 6px -3px rgba(0, 0, 0, 0.16);
  }

  &.left::before {
    right: -4px;
    box-shadow: 2px -5px 6px -3px rgba(0, 0, 0, 0.16);
  }
}
