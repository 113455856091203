.b-custom-mat-tab-group {
  .mat-tab-header {
    padding-bottom: 0 !important;
  }

  .mat-tab-body-content {
    height: auto;
  }

  .mat-tab-label {
    height: 70px;
    font-size: 17px;
    font-weight: 600;
    color: #757575;

    &:focus {
      background-color: transparent !important;
    }

    &.mat-tab-label-active {
      color: #292e30;
    }
  }

  &.m-auto-tab-width {
    .mat-tab-label {
      min-width: 0;
    }
  }
}

.b-custom-mat-tab-group-style-2 {
  .mat-tab-label {
    min-width: 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 1.25rem;

    & + .mat-tab-label {
      margin-left: 50px;
    }
  }

  .mat-tab-label-active {
    opacity: 1;
  }

  .mat-tab-header {
    border-bottom: 0;
  }

  .mat-ink-bar {
    background-color: #fc7d74 !important;
  }
}
