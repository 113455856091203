$bp-laptop: 1850px;

// colors
$c-main: #bd2e7e;
$c-cyan: #00b9b5;
$c-teal: #00b9b5;
$c-pompadour: #6c264d;
$c-light-gray: #f2f6f6;
$c-dim-gray: #707070;
$c-dark-gray: #535353;
$c-brown: #cab299;
$c-montana-gray: #363a3c;
$c-suva-grey: #8e8e8e;
$c-dark: #292f31;
$c-black: #1b1f21;
$c-cod-grey: #292e30;
$c-white: #ffffff;
$c-magenta: #ce1080;

// fonts
$f-main: 'Poppins', sans-serif;

// media-queries
$mq-sm-min: 576px;
$mq-md-min: 768px;
$mq-lg-min: 992px;
$mq-xl-min: 1200px;
$mq-xxl-min: 1920px;
$mq-xxxl-min: 2560px;
$mq-sm-max: $mq-sm-min - 1;
$mq-md-max: $mq-md-min - 1;
$mq-lg-max: $mq-lg-min - 1;
$mq-xl-max: $mq-xl-min - 1;

// builder
$sidebar-inline-elements-gap: 11px;
$sizes: 0 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29 30 31 32 33 34 35 36 37 38 39 40
  42 44 45 46 48 50 52 54 55 56 58 60 62 64 65 66 68 700 72 74 75 76 78 80;

$colors: (
  main: $c-main,
  cyan: $c-cyan,
  teal: $c-teal,
  pompadour: $c-pompadour,
  light-gray: $c-light-gray,
  dim-gray: $c-dim-gray,
  dark-gray: $c-dark-gray,
  brown: $c-brown,
  montana-gray: $c-montana-gray,
  suva-grey: $c-suva-grey,
  dark: $c-dark,
  black: $c-black,
  cod-grey: $c-cod-grey,
  white: $c-white,
);
