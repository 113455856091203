@import '../../styles/vars';

.bs-tabs-2 {
  .mat-tab-label {
    opacity: 1;
    border-right: 1px solid #707070;
    font-size: 12px;
    height: 32px;
    font-weight: 600;
  }

  .mat-tab-label:focus {
    background-color: transparent;
  }

  .mat-tab-label:first-child {
    border-left: 1px solid #707070;
  }

  &.inverse {
    .mat-tab-label {
      color: white;
      border-right-width: 2px;
      font-weight: 400;
    }

    .mat-tab-label:first-child {
      border-left-width: 2px;
    }
  }

  .mat-tab-label.mat-tab-label-active {
    font-weight: 600;
    color: $c-main;
  }

  .mat-tab-header {
    border: 0;
    margin: 0 5px 20px;
  }

  .mat-ink-bar {
    display: none;
  }
}
