.nav-modal-panel {
  border-radius: 26px;
  overflow: hidden;
  border: 1px solid #d8dadc;
}

.localization-language-switcher-panel {
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #d8dadc;
}
