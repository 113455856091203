.nm-tooltip-white {
  line-height: 24px;
  font-weight: 400;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  white-space: pre-wrap;
  text-align: center;
  max-width: 360px !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
  background-color: white !important;
  color: black !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
}
