$left-padding: 30px;

.bs-mat-accordion.mat-accordion {
  display: block;
  background-color: $c-black;

  .mat-expansion-panel,
  .mat-expansion-panel-spacing {
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 0;

    &:first-child {
      margin-top: 0;
      border-radius: 0;
    }

    &:last-child {
      margin-bottom: 0;
      border-radius: 0;
    }
  }

  .mat-expansion-panel {
    box-shadow: none;
    background-color: $c-dark;

    &.disabled {
      .mat-expansion-panel-header-title {
        color: #9a9a9a;
        cursor: default;
      }
    }
  }

  .mat-expansion-panel-header {
    padding-left: 0;

    .mat-icon {
      position: absolute;
      margin-left: -20px;
      height: 20px;
      width: 20px;
      font-size: 20px;
    }

    &.mat-expanded {
      .mat-icon {
        transform: rotate(90deg);
      }
    }
  }

  .mat-expansion-panel-header-title {
    position: relative;
    align-items: center;
    padding-left: $left-padding;
    color: #fff;
    font-size: 14px;
  }

  .mat-expansion-panel-content {
    color: #fff;
  }

  .mat-expansion-panel-body {
    padding-left: $left-padding;
  }

  &.light-theme {
    background-color: #ffffff;
    .mat-expansion-panel-content {
      color: #292e30;
    }
    & > .mat-expansion-panel {
      background-color: #ffffff;

      & > .mat-expansion-panel-header {
        .mat-expansion-panel-header-title {
          color: #292e30;
        }
      }
    }
  }

  .mat-expansion-indicator {
    display: none;
  }
}
