.bs-autocomplete {
  .mat-option {
    background-color: $c-montana-gray;
    color: white;
    font-size: 12px;

    &.mat-option-disabled {
      color: #8c8c8c;
    }
  }

  .mat-option:not(.mat-option-disabled):hover {
    background-color: #3f4446;
  }
}
