.bs-slider.bs-slider.bs-slider {
  width: 100%;
  padding: 0;
  height: 31px;

  .mat-slider-wrapper {
    top: 15px;
    left: 0;
    right: 0;
  }

  .mat-slider-track-background {
    background-color: $c-black;
  }

  .mat-slider-track-fill {
    background-color: $c-black;
  }

  .mat-slider-thumb {
    background-color: #fff;
    border: 0;
  }
  &.light-theme {
    .mat-slider-track-background {
      background-color: #00b9b5;
    }

    .mat-slider-track-fill {
      background-color: #00b9b5;
    }

    .mat-slider-thumb {
      background-color: #00b9b5;
      border: 0;
    }
  }
}
