$header-height: 58px;

.bs-header-container {
  height: $header-height;
  background-color: #1b1f21;
  font-size: 16px;
  color: white;

  .bs-button {
    margin: 0 10px;
  }
}

.bs-header-name {
  width: 185px;
  padding-left: 21px;
  padding-right: 21px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
