.mat-icon {
  &.mat-cyan {
    color: #00b9b5;
  }

  &.mat-primary {
    color: #bd2e7e;
  }

  &.mat-accent {
    color: #bd2e7e;
  }

  &.mat-warn {
    color: #e25874;
  }
}
