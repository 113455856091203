.nm-material-theme .mat-tab-group {
  .mat-tab-label {
    opacity: 1;
    border-right: 1px solid #707070;
    font-size: 12px;
    height: 32px;
    font-weight: 600;

    &:first-child {
      border-left: 1px solid #707070;
    }

    &:hover {
      opacity: 0.8;
    }

    &:focus {
      background-color: transparent;
    }

    &.mat-tab-disabled:hover {
      opacity: 1;
    }
  }

  &.mat-cyan .mat-tab-label-active {
    color: #00b9b5;
  }

  &.mat-primary .mat-tab-label-active {
    color: #bd2e7e;
  }

  &.mat-accent .mat-tab-label-active {
    color: #bd2e7e;
  }

  &.mat-warn .mat-tab-label-active {
    color: #e25874;
  }

  .mat-tab-header {
    border: 0;
    margin: 0 0 16px;
  }

  .mat-ink-bar {
    display: none;
  }
}

.nm-material-theme .mat-mdc-tab-group {
  --mat-tab-header-inactive-label-text-color: #292e30;
  --mat-tab-header-inactive-focus-label-text-color: #292e30;
  --mat-tab-header-inactive-hover-label-text-color: #292e30;

  .mat-mdc-tab-header {
    --mdc-tab-indicator-active-indicator-height: 1px;
    --mdc-secondary-navigation-tab-container-height: 44px;
  }

  .mat-mdc-tab-labels {
    box-shadow: inset 0 -1px 0 #d8dadc;

    .mdc-tab__text-label {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .mat-mdc-tab {
      padding: 0 10px;
    }
  }
}
