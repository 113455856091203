@import './extends';
@import 'vars';

// Material input field custom styles
.mat-custom-form-field {
  .mat-form-field-wrapper {
    padding-bottom: 0px;
  }

  .mat-form-field-underline {
    bottom: 0px;
    height: 2px;
    background-color: #ebebeb;

    .mat-form-field-ripple {
      height: 2px;
    }
  }

  .mat-form-field-infix {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mat-form-field-infix {
    border-top-width: 0;
  }

  .mat-form-field-subscript-wrapper {
    margin-top: 5px;
    top: initial;
  }

  &.big-field {
    width: 300px;

    .mat-form-field-underline {
      background-color: #757575;
    }

    .mat-input-element {
      font-weight: 600;
      color: #000;
      transition: color 250ms;
    }

    .mat-form-field-label {
      font-weight: 600;
    }

    .mat-form-field-subscript-wrapper {
      font-size: 50%;
    }
  }

  &.m-chips-list {
    .mat-chip-list-wrapper {
      min-height: 32px;
      align-items: center;
    }

    .mat-form-field-label {
      top: 25px;
    }
  }

  &.m-off-appearance {
    .mat-form-field-underline {
      visibility: hidden;
    }
  }

  // validation
  &.mat-form-field-invalid {
    .mat-form-field-ripple {
      background-color: #f44336;
    }

    .mat-form-field-label {
      color: #f44336 !important;
    }
  }
}

// Material input field custom styles for admin part
.admin-custom-mat-form-field {
  width: 100%;

  &.mat-form-field-appearance-legacy {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      padding-top: 0;
    }

    .mat-form-field-subscript-wrapper {
      top: 100%;
    }
  }

  .mat-form-field-wrapper {
    width: 100%;
  }

  .mat-form-field-infix {
    padding-top: 0;
    padding-bottom: 0;
    border-top: 0;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-select {
    display: inline-flex;
    align-items: center;
  }

  .mat-select-trigger {
    flex-grow: 1;
  }

  .admin-field {
    flex-grow: 1;
    min-height: 36px;
    padding: 8px 17px;
    border: 1px solid #707070;
    background-color: #fff;
    color: rgb(69, 69, 69);
    font-size: 12px;
  }

  .mat-datepicker-toggle {
    position: absolute;
    right: 0;
    top: -2px;
  }

  &:not(.mat-form-field-should-float) {
    .mat-form-field-label {
      padding-left: 10px;
      padding-top: 2px;
    }
  }
}

.input-text-field {
  width: 100%;
  padding: 8px 15px;
  border: 2px solid rgb(206, 212, 218);
  font-size: 14px;
}

// Custom form fields in material design
.upln-form-field-box {
  position: relative;
  height: 37px;
}

.upln-form-field {
  width: 100%;
  height: 100%;
  background-color: transparent;
  @extend %billing-info-input;

  &:focus {
    outline: none;
    border: 1px solid #bd2e7e;
  }

  &.upln-form-field-select {
    background-color: transparent;
    -webkit-appearance: none;
    padding-right: 20px;
    position: relative;
    background-image: url('../assets/images/onboarding/down-arrow.svg');
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: center right 10px;
  }
}
