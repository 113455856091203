// Atomic styles classes (like pt-100 - padding-top: 100px) are here

// Paddings
.pad-l-0 {
  padding-left: 0;
}

.pad-r-0 {
  padding-right: 0;
}

.pointer-events-none {
  pointer-events: none;
}

.cursor-move {
  &:hover {
    cursor: move !important;
  }
}

.font-weight-semi-bold {
  font-weight: 600;
}
