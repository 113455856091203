@import '../../styles/vars';

.bs-fake-tabs {
  display: flex;

  .tab {
    cursor: pointer;
    border-right: 1px solid #707070;
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    font-weight: 600;
    padding: 0 24px;
    min-width: 160px;
    text-align: center;

    &:first-child {
      border-left: 1px solid #707070;
    }

    &.active {
      color: $c-main;
    }
  }
}
