.bs-color-picker-wrapper {
  display: flex;
  align-items: center;

  .bs-color-picker {
    width: 30px;
    height: 30px;
    border: 1px solid #c3c3c3;
    cursor: pointer;
  }

  .bs-color-picker-value {
    margin-left: 7px;
    font-size: 12px;
    width: 25px;
  }

  .color-picker {
    transition: none;
  }
}
