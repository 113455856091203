@import '../vars';

.panel-content-wrapper {
  color: $c-cod-grey;

  .panel-header {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 33px;
    line-height: 51px;
  }

  .panel-content {
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
  }
}
