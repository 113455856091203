.nm-modal-close-button {
  &.mat-button-base.mat-button,
  &-white.mat-button-base.mat-button {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    min-width: auto;

    &:hover {
      background-image: url('/assets/images/upline/general/close-circle-active.svg');
    }
  }

  &.mat-button-base.mat-button {
    background-image: url('/assets/images/upline/general/close-circle.svg');
  }

  &-white.mat-button-base.mat-button {
    background-image: url('/assets/images/upline/general/close-circle-white.svg');
  }
}

.nm-modal-secondary-footer-action {
  &.mat-button-base.mat-button {
    height: 40px;
    line-height: 40px;
    border-radius: 2px;
    font-weight: bold;
    font-size: 12px;
  }
}
