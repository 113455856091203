.nm-h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
}

.nm-h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
}

.nm-h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}

.nm-h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.nm-text-regular {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.nm-text-bold {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.nm-text-bold-primary {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #bd2e7e;
}

.nm-text-small {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
}

.nm-text-extra-small {
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
}

.nm-text-error {
  @extend .nm-text-small;
  color: #e25874;
}

.nm-underscored-and-highlighted {
  text-decoration-line: underline;
  color: #bd2e7e;
}

.nm-text-purple {
  color: #bd2e7e;
}

.nm-text-input-length {
  @extend .nm-text-small;
  text-align: end;
  padding-right: 8px;
}

.nm-text-regular-title-small {
  font-family: $f-main;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.nm-text-regular-body-large {
  font-family: $f-main;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.nm-text-regular-body-medium {
  font-family: $f-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.nm-text-regular-body-small {
  font-family: $f-main;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.nm-text-regular-label-medium {
  font-family: $f-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.nm-text-regular-label-small {
  font-family: $f-main;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.nm-text-regular-body-extra-small {
  font-family: $f-main;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

.nm-text-bold-display {
  font-family: $f-main;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
}

.nm-text-bold-title-large {
  font-family: $f-main;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
}

.nm-text-bold-title-medium {
  font-family: $f-main;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
}

.nm-text-bold-title-small {
  font-family: $f-main;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.nm-text-bold-body-large {
  font-family: $f-main;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.nm-text-bold-body-medium {
  font-family: $f-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.nm-text-bold-body-small {
  font-family: $f-main;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.nm-text-bold-label-medium {
  font-family: $f-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.nm-text-bold-label-small {
  font-family: $f-main;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.nm-text-color-danger {
  color: #d61e1e;
}

.nm-text-link {
  @extend .nm-text-regular-body-medium;
  color: #bd2e7e;
  cursor: pointer;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;

  &:hover {
    color: #a6276e;
  }

  &:active {
    color: #b8528b;
  }

  &:visited {
    color: #bd2e7e;
  }

  &.disabled {
    cursor: none;
    pointer-events: none;
    color: #a6a6af;
  }
}

.nm-text-disabled {
  color: #a6a6af;
}
