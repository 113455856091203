%field-bottom-line {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #2b2b2b;
}

%billing-info-input {
  border: 1px #c6c6c6 solid;
  border-radius: 5px;
  padding: 0 10px;
}
