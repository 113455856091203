.nm-material-theme .mat-slider {
  &.mat-primary {
    .mat-slider-track-background {
      background-color: #bd2e7e;
      opacity: 0.3;
    }

    .mat-slider-track-fill {
      background-color: #bd2e7e;
    }

    .mat-slider-thumb {
      background-color: #bd2e7e;
    }

    .mat-slider-thumb-label {
      background-color: #bd2e7e;

      &-text {
        color: #ffffff;
      }
    }

    &:not(.mat-slider-disabled).cdk-focused.mat-slider-thumb-label-showing .mat-slider-thumb {
      transform: none;
      outline: 10px solid rgba(103, 80, 164, 0.12);
      border-radius: 50%;
    }
  }

  &.mat-accent {
    .mat-slider-track-background {
      background-color: #bd2e7e;
      opacity: 0.3;
    }

    .mat-slider-track-fill {
      background-color: #bd2e7e;
    }

    .mat-slider-thumb {
      background-color: #bd2e7e;
    }

    .mat-slider-thumb-label {
      background-color: #bd2e7e;

      &-text {
        color: #ffffff;
      }
    }

    &:not(.mat-slider-disabled).cdk-focused.mat-slider-thumb-label-showing .mat-slider-thumb {
      transform: none;
      outline: 10px solid rgba(103, 80, 164, 0.12);
      border-radius: 50%;
    }
  }

  &.mat-slider-horizontal {
    width: 100%;

    .mat-slider-thumb-label {
      top: -46px;
    }

    .mat-slider-wrapper {
      height: 4px;

      .mat-slider-track-wrapper {
        height: 4px;
        border-radius: 2px;

        .mat-slider-track-background,
        .mat-slider-track-fill {
          height: 4px;
        }
      }
    }
  }
}
