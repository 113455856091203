.bs-dropdown-menu {
  &.mat-menu-panel {
    min-height: 50px;
  }

  .mat-menu-item {
    line-height: 33px;
    height: 33px;

    &:hover {
      text-decoration: none;
      color: inherit;
    }

    &[disabled] {
      color: rgba(0, 0, 0, 0.38);
    }
  }

  .mat-icon {
    height: 17px;
    width: 17px;
    font-size: 17px;
    color: #333;
  }

  button[disabled] {
    .mat-icon {
      opacity: 0.4;
    }
  }
}
