.bs-mat-tab-group.mat-tab-group {
  background-color: #fff;

  .mat-tab-label {
    width: 100%;
  }

  .mat-ink-bar {
    background-color: $c-main;
    height: 3px;
  }

  .mat-tab-header {
    border-bottom: none;
    background-color: $c-dark;
  }

  .mat-tab-label {
    color: #fff;
    opacity: 1;
    min-width: 0;

    &.mat-tab-disabled {
      opacity: 0.6;
    }
  }

  .mat-tab-body {
    background-color: $c-dark;
    color: #fff;
  }
}
