@import '../vars';

.modal-black {
  position: relative;
  background: #292e30;
  color: white;
  padding: 40px;
  height: 100%;
  border-radius: 4px;

  .mat-dialog-actions {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .icon-content {
    height: 150px;
    width: 150px;
    margin: auto;
    background-color: white;
    border-radius: 100%;

    .icon-large {
      width: 72px;
      height: 72px;
    }
  }

  .text-content-center {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
  }

  .close-button {
    position: absolute;
    right: 10px;
    top: 8px;
    background-color: transparent;
    border: 0;
    outline: none;
    cursor: pointer;

    .mat-icon {
      width: 20px;
      height: 20px;
      line-height: 20px;
    }
  }

  .dialog-header-text {
    font-weight: 600;
  }

  .mat-dialog-actions {
    justify-content: space-between;
  }
}

.modal-white {
  position: relative;
  padding: 40px;
  color: #292e30;
  height: 100%;
  border-radius: 4px;

  .mat-dialog-actions {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .icon-content {
    height: 150px;
    width: 150px;
    margin: auto;
    background-color: white;
    border-radius: 100%;

    .icon-large {
      width: 72px;
      height: 72px;
    }
  }

  .text-content-center {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
  }

  .close-button {
    position: absolute;
    right: 10px;
    top: 8px;
    background-color: transparent;
    border: 0;
    outline: none;
    cursor: pointer;

    .mat-icon {
      width: 20px;
      height: 20px;
      line-height: 20px;
    }
  }

  .dialog-header-text {
    font-weight: 600;
  }

  .mat-dialog-actions {
    justify-content: space-between;
  }
}
