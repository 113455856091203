.transparent-backdrop {
  background-color: transparent;
}

.transparent-background {
  .mat-dialog-container {
    background-color: transparent;
    box-shadow: none;
  }
}

.visible-dialog-overflow {
  .mat-dialog-container {
    overflow: visible;
  }
}
