@import 'vars';

.image-upload-preview {
  cursor: pointer;
  position: relative;

  img {
    height: 175px;
  }

  .image-upload-preview-delete-button,
  .main-container button.image-upload-preview-delete-button {
    position: absolute;
    top: 10px;
    right: 15px;
    display: inline-flex;
    padding: 7px;
    border-radius: 5px;
    opacity: 0.5;
    transition: opacity 300ms;
    cursor: pointer;
    background-color: rgba(#fff, 0.5);

    &:hover {
      opacity: 1;
    }

    &:focus {
      outline: none;
    }
  }
}

.image-upload-label {
  width: 100%;
  min-height: 135px;
  padding: 20px 25px;
  border: 1px dashed #292e30;
  background-color: #ffffff;
  cursor: pointer;

  .image-upload-label-add-image-text {
    margin-left: 15px;
    font-size: 12px;
    color: $c-main;
  }

  .image-upload-label-tips {
    font-size: 9px;
  }

  .image-upload-label-title {
    margin-bottom: 10px;
  }
}
