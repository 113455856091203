.nm-material-theme .mat-radio-button {
  &.mat-cyan {
    &.mat-radio-checked {
      .mat-radio-inner-circle {
        background-color: #00b9b5;
      }

      .mat-radio-outer-circle {
        border-color: #00b9b5;
      }
    }

    .mat-ripple-element {
      background-color: #00b9b5 !important;
    }
  }

  &.mat-primary {
    &.mat-radio-checked {
      .mat-radio-inner-circle {
        background-color: #bd2e7e;
      }

      .mat-radio-outer-circle {
        border-color: #bd2e7e;
      }
    }

    .mat-ripple-element {
      background-color: #bd2e7e !important;
    }
  }

  &.mat-accent {
    &.mat-radio-checked {
      .mat-radio-inner-circle {
        background-color: #bd2e7e;
      }

      .mat-radio-outer-circle {
        border-color: #bd2e7e;
      }
    }

    .mat-ripple-element {
      background-color: #bd2e7e !important;
    }
  }

  &.mat-warn {
    &.mat-radio-checked {
      .mat-radio-inner-circle {
        background-color: #e25874;
      }

      .mat-radio-outer-circle {
        border-color: #e25874;
      }
    }

    .mat-ripple-element {
      background-color: #e25874 !important;
    }
  }

  &.mat-radio-disabled {
    .mat-radio-inner-circle,
    .mat-radio-outer-circle {
      opacity: 0.54;
    }
  }
}
