.edit-item-sidebar {
  position: relative;
  padding: 64px 20px 20px 20px;

  .edit-item-sidebar-title {
    color: #292e30;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .edit-item-sidebar-block-title {
    margin-bottom: 0px;
    margin-top: 30px;
    color: #292e30;
    font-size: 18px;
    font-weight: 600;
  }

  .edit-item-sidebar-form {
    display: flex;
    flex-direction: column;
  }

  .control-hint {
    font-size: 12px;
    line-height: 24px;
    color: #757575;
  }

  .image-cropper {
    display: block;
  }

  .save-button {
    position: absolute;
    top: 16px;
    right: 32px;
    font-size: 17px;
    cursor: pointer;
    background-color: transparent;
  }

  .save-button-disabled {
    cursor: no-drop;
  }

  .toggle-box {
    margin-top: 20px;

    .mat-slide-toggle {
      width: 100%;
    }

    .mat-slide-toggle-bar {
      margin-left: auto;
    }

    .mat-slide-toggle-content {
      font-size: 13px;
      color: #757575;
    }
  }

  .target-box {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .target-box-label {
    font-size: 13px;
    color: #757575;
  }

  .target-box-stepper {
    margin-left: auto;
  }
}
