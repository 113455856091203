.team-member-container {
  margin-top: 16px;

  img {
    height: 48px;
    width: 48px;
    border-radius: 50px;
    margin-right: 32px;
    flex-shrink: 0;
  }

  .user-info-container {
    .nickname {
      font-size: 17px;
      color: #292e30;
    }

    .username {
      font-size: 11px;
      color: #757575;
    }
  }

  .total-points {
    font-size: 17px;
    color: #292e30;
    font-weight: 600;
  }
}

.team-member-expanded {
  display: flex;
  flex-direction: column;
  margin-top: 13px;
  border: 1px solid rgba(#757575, 0.4);
  border-radius: 10px;
  cursor: pointer;
  padding: 8px 12px 10px 15px;

  .team-member-expanded-date {
    margin-left: auto;
    color: #757575;
    font-size: 12px;
  }

  .team-member-expanded-info {
    display: flex;
    align-items: center;
  }

  .team-member-expanded-avatar {
    flex-shrink: 0;
    height: 44px;
    width: 44px;
    margin-right: 32px;
    border-radius: 50%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  }

  .team-member-expanded-meta-data {
  }

  .team-member-expanded-nickname {
    font-size: 17px;
    color: #292e30;
    font-weight: 600;
  }

  .team-member-expanded-username {
    font-size: 12px;
    color: #757575;
  }

  .team-member-expanded-actions {
    display: flex;
    margin-left: auto;
    margin-top: 25px;

    .b-button {
      min-width: 0;
      color: #757575;
    }
  }
}
