.nm-material-theme .mat-chip,
.mat-standard-chip {
  height: auto;
  font-size: 12px;
  cursor: pointer;
  background-color: #ffffff;
  color: #292e30;
  padding: 0 16px;
  min-height: 30px;

  &.mat-chip-selected {
    &.mat-primary {
      background-color: #bd2e7e;
      color: #ffffff;
    }

    &.mat-accent {
      background-color: #bd2e7e;
      color: #ffffff;
    }
  }

  &.mat-chip-disabled {
    pointer-events: none;
  }

  &.nm-chip-grey {
    color: #707070;
    background: #f2f6f6;

    &.mat-standard-chip .mat-chip-remove.mat-icon {
      opacity: 1;
      font-size: 12px;
      line-height: 18px;
      min-width: 18px;
      text-align: center;
      background: white;
      border-radius: 50%;
    }
  }
}
