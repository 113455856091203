.bs-chip {
  &.mat-chip {
    border-radius: 5px;
    color: white;

    .mat-icon.mat-chip-remove {
      color: white;
      opacity: 1;
    }
  }

  &.dark {
    background-color: #363a3c;
  }
}
