.r-link {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }

  &:visited {
    color: inherit;
  }
}

.r-button {
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  border: 0;
}

.r-heading {
  margin-top: 0;
  margin-bottom: 0;
}

.r-paragraph {
  margin-top: 0;
  margin-bottom: 0;
}

.r-body {
  margin: 0;
}

.r-list {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
